import { Text } from "@components/ui";
import { Content } from "@prismicio/client";
import {
  PrismicImage,
  PrismicRichText,
  SliceComponentProps,
} from "@prismicio/react";
import style from "./HomeCard.module.scss";

/**
 * Props for `HomeCard`.
 */
export type HomeCardProps = SliceComponentProps<Content.HomeCardSlice>;

/**
 * Component for "HomeCard" Slices.
 */
const HomeCard = ({ slice }: HomeCardProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.section}
    >
      <div className={style.prouvedWrapper}>
        <div className={style.prouved}>
          <div className={style.title}>
            <PrismicImage width={62} field={slice.primary.icon} alt="" />
            <span>
              <PrismicRichText field={slice.primary.title} />
            </span>
          </div>
          <Text className={style.text}>
            <PrismicRichText field={slice.primary.text} />
          </Text>
          <div className={style.logos}>
            {slice.items.map((item, index) => (
              <PrismicImage key={index} field={item.logo} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCard;
